// **********
// @import libraries
// **********
require('animate.css');
const WOW = require('wowjs/dist/wow.min');


// **********
// @import bootstrap components
// **********



// **********
// @import form
// **********


// **********
// @import modal
// **********



// **********
// @import teaser
// **********



// **********
// @import detail
// **********



// **********
// @import components
// **********
require('../../components/homepage/content-services');
require('../../components/homepage/content-contact');



// **********
// custom scripts
// **********
wow = new WOW.WOW({
        boxClass:     'wow',
        animateClass: 'animated',
        offset:       0,
        mobile:       true,
        live:         false,
        scrollContainer: null
});
wow.init();



// **********
// @import page style .scss
// **********
require('./index.scss');