// **********
// custom scripts
// **********
$(".sc-Img_Hover-Zoom").click(function() {
    window.location = $(this).find("a").attr("href");
    return false;
});


// **********
// @import page style .scss
// **********
require('./content-services.scss');